/*
 * Copyright (C) 2023,  Chistel Brown,  - All Rights Reserved
 * @project                  new-backend
 * @file                           wrapper.js
 * @author                  Chistel Brown
 * @site                          <http://twitter.com/chistelbrown>
 * @email                      chistelbrown@gmail.com
 * @lastmodified     25/02/2023, 06:33
 */

function getThemeFromLocalStorage() {
    // if user already changed the theme, use it
    if (window.localStorage.getItem('dark')) {
        return JSON.parse(window.localStorage.getItem('dark'))
    }

    // else return their preferences
    return (
        !!window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: light)').matches
    )
}

function setThemeToLocalStorage(value) {
    window.localStorage.setItem('dark', value)
}


export default () => ({
    isSidebarOpen: false,
    subIsOpen: false,
    viewPort: window.innerWidth,
    isMobile: false,
    dark: getThemeFromLocalStorage(),
    mobile() {
        return (window.screen.width <= 640) ||
            (window.matchMedia &&
                window.matchMedia('only screen and (max-width: 640px)').matches
            );

    },
    watchScreen() {
        if (!this.mobile()) {
            this.isSidebarOpen = true
        } else {
            this.isMobile = true;
            this.isSidebarOpen = false
        }

        if (this.isSidebarOpen) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }
    },
    toggleTheme() {
        this.dark = !this.dark
        setThemeToLocalStorage(this.dark)
    },
    toggleSideMenu() {
        this.isSidebarOpen = !this.isSidebarOpen
    },
    closeSideMenu() {
        this.isSidebarOpen = false
    }
})
