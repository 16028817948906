/*
 * Copyright (C) 2023,  Chistel Brown,  - All Rights Reserved
 * @project                  new-backend
 * @file                           toast.js
 * @author                  Chistel Brown
 * @site                          <http://twitter.com/chistelbrown>
 * @email                      chistelbrown@gmail.com
 * @lastmodified     25/02/2023, 06:34
 */

export default {
    counter: 0,
    list: [],
    createToast(message, type = "info") {
        const index = this.list.length
        let totalVisible =
            this.list.filter((toast) => {
                return toast.visible
            }).length + 1
        this.list.push({
            id: this.counter++,
            message,
            type,
            visible: true,
        })
        setTimeout(() => {
            this.destroyToast(index)
        }, 2000 * totalVisible)
    },
    destroyToast(index) {
        this.list[index].visible = false
    }
};
