/*
 * Copyright (C) 2023,  Chistel Brown,  - All Rights Reserved
 * @project                  new-backend
 * @file                           select.js
 * @author                  Chistel Brown
 * @site                          <http://twitter.com/chistelbrown>
 * @email                      chistelbrown@gmail.com
 * @lastmodified     13/04/2023, 08:01
 */

Livewire.on('livewire-select-focus-search', (data) => {
    const el = document.getElementById(`${data.name || 'invalid'}`);

    if (!el) {
        return;
    }

    el.focus();
});

Livewire.on('livewire-select-focus-selected', (data) => {
    const el = document.getElementById(`${data.name || 'invalid'}-selected`);

    if (!el) {
        return;
    }

    el.focus();
});
