
import Alpine from 'alpinejs'
import './bootstrap';
import hljs from 'highlight.js';
import Choices from 'choices.js';
import ApexCharts from 'apexcharts'
import Pikaday from 'pikaday/pikaday';
import focus from '@alpinejs/focus'
import 'flatpickr';
// import 'livewire-sortable'
import './components/sortable-js';
import * as FilePond from 'filepond';
import wrapper from './components/wrapper'
import toast from './components/toast';
import './components/editor';
import './components/select'
window.Alpine = Alpine;

window.FilePond = FilePond;
window.ApexCharts = ApexCharts;
window.Pikaday = Pikaday;
Alpine.plugin(focus);
Alpine.store('toasts', toast);
Alpine.data('wrapper', wrapper);
Alpine.start()

// Create a multiselect element.
window.choices = (element, maxItemCount = 3) => {
    return new Choices(element, { maxItemCount: maxItemCount, removeItemButton: true });
};

// Syntax highlight code blocks.
window.highlightCode = (element) => {
    element.querySelectorAll('pre code').forEach((block) => {
        hljs.highlightBlock(block);
    });
};
